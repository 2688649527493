<template>
  <el-dialog title="批量编辑" :visible="visible" :close-on-click-modal="false" width="500px" @close="close">
    <el-form ref="editForm" :model="editForm.data" label-position="right" label-width="120px" :inline-message="true">
      <template v-for="(item, index) in editForm.items">
        <edit-form-item v-if="index == 0 || editForm.data.EditColumn == item.prop" :key="index" :form="editForm.data"
          :item="item" labelWidth="70px" tipWidth="100px"></edit-form-item>
      </template>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { submit } from "@/api/base";

export default {
  name: "ProductEditMore",
  data() {
    return {
      visible: false,
      editForm: {
        url: "/api/product/editMore",
        saveUrl: "/api/product/doEditMore",
        items: [
          {
            type: "select",
            label: "修改项",
            prop: "EditColumn",
            dataType: "string",
            width: "420px",
            isRequired: true,
            optionJson:
              '[{"label":"商品分类","value":"CateID"},{"label":"品牌","value":"BrandID"},{"label":"单位","value":"Units"},{"label":"重量","value":"Weight"},{"label":"警戒库存","value":"WarnStock"},{"label":"是否专款","value":"isMonopoly"},{"label":"是否启用批次","value":"IsBatch"},{"label":"是否启用保质期","value":"IsExpireDate"},{"label":"单件体积","value":"Volume"},{"label":"是否赠品","value":"IsGift"},{"label":"供应商","value":"SupplierID"}]',
          },
          {
            type: "select-tree",
            label: "商品分类",
            prop: "CateID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "CateList",
          },
          {
            type: "select",
            label: "品牌",
            prop: "BrandID",
            dataType: "number",
            width: "420px",
            isRequired: true,
            optionField: "BrandList",
          },
          {
            type: "input",
            label: "单位",
            prop: "Units",
            dataType: "string",
            width: "420px",
          },
          {
            type: "input-decimal(3)",
            label: "重量",
            prop: "Weight",
            dataType: "number",
            width: "420px",
          },
          {
            type: "input-int",
            label: "警戒库存",
            prop: "WarnStock",
            dataType: "number",
            width: "420px",
          },
          {
            type: "switch-int",
            label: "是否专款",
            prop: "isMonopoly",
            dataType: "number",
            width: "420px",
            defaultValue: 0,
          },
          {
            type: "switch-int",
            label: "是否启用批次",
            prop: "IsBatch",
            dataType: "number",
            width: "420px",
            defaultValue: 0,
          },
          {
            type: "switch-int",
            label: "是否启用保质期",
            prop: "IsExpireDate",
            dataType: "number",
            width: "420px",
            defaultValue: 0,
          },
          {
            type: "input-decimal",
            label: "单件体积",
            prop: "Volume",
            dataType: "number",
            width: "420px",
          },
          {
            type: "switch-int",
            label: "是否赠品",
            prop: "IsGift",
            dataType: "number",
            width: "420px",
            defaultValue: 0,
          },
          {
            type: "select",
            label: "供应商",
            prop: "SupplierID",
            dataType: "number",
            width: "420px",
            optionField: "SupplierList",
          },
        ],
        data: {
          ProductIDs: "",
          EditColumn: "CateID",
          CateID: null,
          BrandID: null,
          Units: "",
          Weight: "",
          WarnStock: null,
          isMonopoly: 0,
          IsBatch: 0,
          IsExpireDate: 0,
          Volume: null,
          IsGift: 0,
          SupplierID: null,
        },
        rules: {},
      },
    };
  },
  methods: {
    init(productIDs) {
      this.visible = true;
      this.editForm.data.ProductIDs = productIDs;
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    async fetchData() {
      const { data } = await submit(this.editForm.url);
      let item = this.editForm.items.find((item) => item.prop == "Weight");
      item.label = `重量（${data.InitData.WeightUnit}）`;
      const { items, rules } = this.getFormRules(
        this.editForm.items,
        data.InitData
      );
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          let param = {
            productIDs: this.editForm.data.ProductIDs,
            editColumn: this.editForm.data.EditColumn,
            editData: JSON.stringify(this.editForm.data),
          };
          await submit(this.editForm.saveUrl, param);
          this.$baseMessage("保存成功", "success");
          this.$emit("handleQuery");
          this.close();
        } else {
          return false;
        }
      });
    },
    close() {
      this.editForm.data.EditColumn = "CateID";
      this.editForm.data.CateID = null;
      this.editForm.data.BrandID = null;
      this.editForm.data.Units = "";
      this.editForm.data.Weight = "";
      this.editForm.data.WarnStock = null;
      this.editForm.data.isMonopoly = 0;
      this.editForm.data.IsBatch = 0;
      this.editForm.data.IsExpireDate = 0;
      this.editForm.data.Volume = null;
      this.editForm.data.IsGift = 0;
      this.editForm.data.SupplierID = null;
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
